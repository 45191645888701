import { useState, useMemo, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';

import classes from './Search.module.css';
import useTranslation from '../../../hooks/useTranslation';

const Search = (props) => {
    const [searchStr, setSearchStr] = useState('');
    const [showSearchResults, setShowSearchResults] = useState(false);

    const locations = useSelector((state) => state.app.locations);
    const lang = useSelector((state) => state.app.lang);

    const filteredLocations = useMemo(() => {
        let fl = [...locations];

        if (searchStr !== '') {
            fl = locations.filter((b) => b['name_' + lang].toLowerCase().includes(searchStr.toLowerCase()));
        }

        return fl;
    }, [searchStr]);

    const noResultsTrans = useTranslation('No results were found');

    // Click outside
    const useOutsideClick = (callback) => {
        const ref = useRef();

        useEffect(() => {
            const handleClick = (event) => {
                if (ref.current && !ref.current.contains(event.target)) {
                    callback();
                }
            };

            document.addEventListener('click', handleClick);

            return () => {
                document.removeEventListener('click', handleClick);
            };
        }, [ref]);

        return ref;
    };
    const closeDropdown = () => {
        setShowSearchResults(false);
    };
    const ref = useOutsideClick(closeDropdown);
    // ----------------------

    const updateSelectedLocation = (location) => {
        props.onSelected(location);
        setShowSearchResults(false);
    };

    return (
        <div className={classes.SearchWrapper} ref={ref}>
            <div className={classes.Search}>
                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
                    <path d='M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z'></path>
                </svg>
                <input
                    type='text'
                    placeholder={useTranslation('Find location')}
                    onClick={() => setShowSearchResults(true)}
                    onChange={(e) => setSearchStr(e.target.value)}
                />
                <svg
                    className={showSearchResults ? [classes.Caret, classes.CaretDown].join(' ') : classes.Caret}
                    onClick={() => setShowSearchResults(!showSearchResults)}
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 320 512'
                >
                    <path d='M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z' />
                </svg>
            </div>

            <div className={showSearchResults ? [classes.SearchResults, classes.Show].join(' ') : classes.SearchResults}>
                {filteredLocations.length === 0 ? (
                    <span className={classes.NoResults}>{noResultsTrans}</span>
                ) : (
                    filteredLocations.map((fLocation, index) => {
                        return (
                            <div key={index} className={classes.FilteredLocation} onClick={() => updateSelectedLocation(fLocation)}>
                                {fLocation['name_' + lang]}
                            </div>
                        );
                    })
                )}
            </div>
        </div>
    );
};

export default Search;
