import { useEffect, useState } from 'react';

import classes from './Offline.module.css';

function Offline({ children }) {
    const [elClasses, setElClasses] = useState([classes.OfflineWrapper]);

    useEffect(() => {
        if (!navigator.onLine) {
            setElClasses([classes.OfflineWrapper, classes.IsOffline]);
        } else {
            setElClasses([classes.OfflineWrapper]);
        }
    }, [navigator.onLine]);

    return (
        <div className={elClasses.join(' ')}>
            <div className={classes.OfflineContent}>
                {/* <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='currentColor'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                >
                    <path d='M18.36 6.64a9 9 0 1 1-12.73 0'></path>
                    <line x1='12' y1='2' x2='12' y2='12'></line>
                </svg> */}

                <h2>ALTER TRIP</h2>

                <div className={classes.OfflineText}>
                    <h3>Φαίνεται ότι είστε offline</h3>
                    <p>Το Alter trip χρειάζεται internet και φαίνεται ότι η συσκευή σας δεν είναι συνδεδεμένη αυτή τη στιγμή.</p>
                    <p>Ελέγξτε τη σύνδεσή σας και κάντε κλικ στο παρακάτω κουμπί για να γίνει νέα προσπάθεια σύνδεσης</p>
                </div>

                <div className={classes.OfflineButtonWrapper}>
                    <button onClick={() => window.location.reload(false)}>Επαναφόρτωση</button>
                </div>
            </div>
            <div className={classes.Overlay}></div>
            {children}
        </div>
    );
}

export default Offline;
