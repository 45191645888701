import { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import L from 'leaflet';
import { Tooltip, MapContainer, ZoomControl, TileLayer, FeatureGroup, CircleMarker, Popup, GeoJSON, Marker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
// import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import 'leaflet-routing-machine';
import 'leaflet-routing-machine/dist/leaflet-routing-machine.css';

import Search from './features/Search';
import ZoomToGeolocation from './features/ZoomToGeolocation';
import classes from './Map.module.css';
import MapRoutes from './features/MapRoutes';
import LocationPopup from './features/LocationPopup';
import { appActions } from '../../app/appSlice';
import { locationsActions } from '../Locations/locationsSlice';
import { getRoute } from './mapAsyncActions';
import Modal from '../../features/UI/Modal/Modal';
import LocationDetails from '../../features/LocationDetails/LocationDetails';
import useTranslation from '../../hooks/useTranslation';
import { showMessage } from '../../utils/messageHandler';

const Map = () => {
    const dispatch = useDispatch();
    const featureGroupRef = useRef();
    const geoNotAvailableTrans = useTranslation('Geolocation is not available');
    const geoTimeoutTrans = useTranslation('Geolocation timed out');
    const geoErrorTrans = useTranslation('Something went wrong!');

    const locations = useSelector((state) => state.app.locations);
    const zoomToLocation = useSelector((state) => state.app.zoomToLocation);
    const directions = useSelector((state) => state.app.directions);
    const directionsType = useSelector((state) => state.app.directionsType);
    const geolocation = useSelector((state) => state.app.geolocation);
    const selectedLocation = useSelector((state) => state.locations.selectedLocation);
    const checkedGeolocation = useSelector((state) => state.app.checkedGeolocation);

    const [map, setMap] = useState(null);
    const [routeStops, setRouteStops] = useState(locations.length);
    const [geolocationId, setGeolocationId] = useState(null);

    // const maxBounds = useMemo(() => {
    //     const southWest = L.latLng(40.499399, 22.81289);
    //     const northEast = L.latLng(40.704731, 23.046431);
    //     const bounds = L.latLngBounds(southWest, northEast);

    //     return bounds;
    // }, []);

    // const handleGeolocation = (permissionStatus) => {
    //     if (permissionStatus?.state === 'granted' && !geolocationId) {
    //         const geoId = navigator.geolocation.watchPosition(
    //             function (position) {
    //                 dispatch(appActions.setGeoLocation([position.coords.latitude, position.coords.longitude]));
    //                 dispatch(appActions.setCheckedGeoLocation(true));
    //             },
    //             function (error) {
    //                 dispatch(appActions.setGeoLocation(null));
    //                 dispatch(appActions.setCheckedGeoLocation(true));
    //             }
    //         );
    //         setGeolocationId(geoId);
    //     } else if (geolocationId) {
    //         navigator.geolocation.clearWatch(geolocationId);
    //         setGeolocationId(null);
    //         dispatch(appActions.setGeoLocation(null));
    //     } else {
    //         dispatch(appActions.setGeoLocation(null));
    //     }
    // };

    // useEffect(() => {
    //     navigator.permissions.query({ name: 'geolocation' }).then((permissionStatus) => {
    //         handleGeolocation(permissionStatus);

    //         permissionStatus.onchange = () => {
    //             handleGeolocation(permissionStatus);
    //         };

    //         dispatch(appActions.setCheckedGeoLocation(true));
    //     });

    //     return () => {
    //         dispatch(appActions.setZoomToLocation(null));
    //     };
    // }, []);

    useEffect(() => {
        if (!geoNotAvailableTrans) return;

        if (navigator.geolocation) {
            if (geolocationId) {
                navigator.geolocation.clearWatch(geolocationId);
                setGeolocationId(null);
                dispatch(appActions.setGeoLocation(null));
            }

            const geoId = navigator.geolocation.watchPosition(
                function (position) {
                    dispatch(appActions.setGeoLocation([position.coords.latitude, position.coords.longitude]));
                },
                function (error) {
                    const message = (
                        <div className='LearnMoreError'>
                            <span>{geoNotAvailableTrans}</span>
                            <span
                                className='LearnMoreButton'
                                onClick={() =>
                                    window.open(
                                        'https://support.google.com/maps/answer/2839911?hl=en&authuser=0&visit_id=638079224355221383-3252913396&co=GENIE.Platform%3DDesktop&oco=&p=browser_lp&rd=1#permission',
                                        '_blank',
                                        'noopener,noreferrer'
                                    )
                                }
                            >
                                Learn more
                            </span>
                        </div>
                    );

                    switch (error.code) {
                        case error.PERMISSION_DENIED:
                            // The user denied the request for location information
                            showMessage(message, 'error', true);
                            break;
                        case error.POSITION_UNAVAILABLE:
                            // The location information is unavailable
                            showMessage(geoNotAvailableTrans, 'error', true);
                            break;
                        case error.TIMEOUT:
                            // The request to get user location timed out
                            showMessage(geoTimeoutTrans, 'error', true);
                            break;
                        case error.UNKNOWN_ERROR:
                            // An unknown error occurred
                            showMessage(geoErrorTrans, 'error', true);
                            break;
                    }
                }
            );
            setGeolocationId(geoId);
        } else {
            // Geolocation is not supported by the browser
            showMessage(geoNotAvailableTrans, 'error', true);
        }

        dispatch(appActions.setCheckedGeoLocation(true));

        return () => {
            dispatch(appActions.setZoomToLocation(null));
        };
    }, [geoNotAvailableTrans]);

    useEffect(() => {
        if (!map) return;
        if (!featureGroupRef.current) return;
        if (zoomToLocation) return;

        map.fitBounds(featureGroupRef.current.getBounds());
    }, [map, featureGroupRef?.current, zoomToLocation]);

    useEffect(() => {
        if (!map) return;
        if (!zoomToLocation) return;
        map.setView([zoomToLocation.lat, zoomToLocation.lon], 19);
    }, [map, zoomToLocation]);

    useEffect(() => {
        // if (!map) return;
        // if (map && routingControl) {
        //     map.removeControl(routingControl);
        //     setRoutingControl(null);
        // }

        if (locations) {
            let waypoints = [];
            for (let i = 0; i < locations.length; i++) {
                const location = locations[i];
                if (!location.remove) waypoints.push([parseFloat(location.lon), parseFloat(location.lat)]);
            }

            if (waypoints.length < 2) {
                dispatch(appActions.setDirections(null));
            } else {
                dispatch(getRoute(waypoints, directionsType));
            }

            setRouteStops(waypoints.length);

            // let customPane = map.createPane('customPane');
            // customPane.style.zIndex = 390;

            // Routing
            // const control = L.Routing.control({
            //     waypoints: waypoints,
            //     lineOptions: {
            //         // styles: [{ pane: 'customPane', color: '#6FA1EC', weight: 4 }],
            //         styles: [{ color: '#6FA1EC', weight: 4 }],
            //     },
            //     createMarker: function () {
            //         return null;
            //     },
            //     show: false,
            //     urlParameters: { vehicle: 'foot' },
            //     addWaypoints: false,
            //     draggableWaypoints: false,
            //     fitSelectedRoutes: true,
            //     showAlternatives: false,
            //     // waypointMode: 'snap',
            // });

            // setRoutingControl(control);

            // control._map = map;

            // const controlDiv = control.onAdd(map);

            // Distance
            // if (waypoints.length < 2) {
            //     setRouteDistance(0);
            // }

            // control.on('routesfound', function (e) {
            //     const routes = e.routes;
            //     const summary = routes[0].summary;
            //     const totalDistance = summary.totalDistance / 1000;
            //     setRouteDistance(totalDistance);
            // });
        }
    }, [locations, directionsType]);

    const getIcon = () => {
        let circleIcon =
            '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"/></svg>';

        // Custom circle icon
        const blinkingCircle = L.divIcon({
            iconAnchor: [0, 12],
            labelAnchor: [-6, 0],
            popupAnchor: [0, -36],
            html: `<div class=${classes.BlueCircle}>${circleIcon}</div>`,
            className: 'dummy', // Removes white square
        });

        return blinkingCircle;
    };

    return (
        <div className={classes.MapContainer}>
            <Search map={map} onSelected={(location) => dispatch(appActions.setZoomToLocation(location))} />
            {checkedGeolocation && <ZoomToGeolocation />}

            <div className={classes.Map}>
                <MapContainer
                    center={[40.63, 22.95]}
                    zoom={13}
                    whenReady={(mapObj) => setMap(mapObj.target)}
                    boundsOptions={{ padding: [50, 50] }}
                    zoomControl={false}
                    // maxBounds={maxBounds}
                >
                    <ZoomControl position='topright' />
                    <TileLayer attribution='' url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}' />

                    {/* <TileLayer attribution='' url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}' /> */}
                    {/* <TileLayer attribution='' url='https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png' /> */}
                    {/* <ReactLeafletGoogleLayer
                        apiKey={CONFIG.mapKey}
                        type={'roadmap'}
                        styles={[
                            {
                                featureType: 'poi',
                                language: 'gr',
                                stylers: [
                                    {
                                        visibility: 'off',
                                    },
                                ],
                            },
                        ]}
                    /> */}

                    {directions && <GeoJSON key={Math.random()} data={directions.features[0]} />}
                    {geolocation && <Marker key={Math.random()} icon={getIcon()} position={geolocation}></Marker>}

                    {locations && locations.length > 0 && (
                        <FeatureGroup ref={featureGroupRef}>
                            {locations.map((location, index) => {
                                return (
                                    <CircleMarker
                                        key={`${index}${location.remove}`}
                                        center={[location.lat, location.lon]}
                                        radius={10}
                                        fillColor='#2D5785'
                                        color='#2D5785'
                                        fillOpacity={location.remove ? 0.3 : 1}
                                        opacity={location.remove ? 0 : 1}
                                    >
                                        <Popup>
                                            <LocationPopup location={location} />
                                        </Popup>
                                        <Tooltip direction='right' offset={[0, 0]} opacity={location.remove ? 0.5 : 1} permanent>
                                            {index + 1}
                                        </Tooltip>
                                    </CircleMarker>
                                );
                            })}
                        </FeatureGroup>
                    )}
                </MapContainer>
            </div>

            <div className={classes.Routes}>
                <MapRoutes routeStops={routeStops} />
            </div>

            <Modal show={selectedLocation} onClose={() => dispatch(locationsActions.setSelectedLocation({ location: null, images: [] }))}>
                {selectedLocation ? <LocationDetails /> : null}
            </Modal>
        </div>
    );
};

export default Map;
