import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import classes from './LocationDetails.module.css';

const LocationDetails = () => {
    const selectedLocation = useSelector((state) => state.locations.selectedLocation);
    const selectedLocationImages = useSelector((state) => state.locations.selectedLocationImages);

    const [playStatus, setPlayStatus] = useState('stopped');

    const lang = useSelector((state) => state.app.lang);

    const audioFile = useMemo(() => {
        if (!selectedLocation) return null;
        const audioFilePath = selectedLocation['mp3_' + lang];
        if (!audioFilePath) return null;

        return new Audio(`${process.env.PUBLIC_URL}/mp3/${audioFilePath}`);
    }, [selectedLocation]);

    useEffect(() => {
        return () => {
            stopAudio();
        };
    }, []);

    const playAudio = () => {
        if (!audioFile) return;
        audioFile.play();
        setPlayStatus('playing');
    };

    const pauseAudio = () => {
        if (!audioFile) return;
        audioFile.pause();
        setPlayStatus('paused');
    };

    const stopAudio = () => {
        if (!audioFile) return;
        audioFile.pause();
        audioFile.currentTime = 0;
        setPlayStatus('stopped');
    };

    return (
        selectedLocation && (
            <div className={classes.LocationDetails}>
                <div className={classes.ImageCarousel}>
                    <Swiper
                        className={classes.Swiper}
                        pagination={true}
                        navigation={true}
                        modules={[Pagination, Navigation]}
                        style={{
                            '--swiper-navigation-color': '#fff',
                            '--swiper-pagination-color': '#fff',
                        }}
                    >
                        {selectedLocationImages.map((image, index) => {
                            return (
                                <SwiperSlide key={index}>
                                    <img src={require(`../../assets/images/${image}`)} alt={image} />
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                </div>

                <div className={classes.Actions}>
                    {playStatus !== 'playing' && (
                        <div className={classes.Action} onClick={playAudio}>
                            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
                                <path d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm115.7 272l-176 101c-15.8 8.8-35.7-2.5-35.7-21V152c0-18.4 19.8-29.8 35.7-21l176 107c16.4 9.2 16.4 32.9 0 42z' />
                            </svg>
                            <span>{playStatus === 'stopped' ? 'Listen' : 'Continue'}</span>
                        </div>
                    )}

                    {playStatus === 'playing' && (
                        <>
                            <div className={[classes.Action, classes.SmallIconWrapper].join(' ')} onClick={pauseAudio}>
                                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'>
                                    <path d='M144 479H48c-26.5 0-48-21.5-48-48V79c0-26.5 21.5-48 48-48h96c26.5 0 48 21.5 48 48v352c0 26.5-21.5 48-48 48zm304-48V79c0-26.5-21.5-48-48-48h-96c-26.5 0-48 21.5-48 48v352c0 26.5 21.5 48 48 48h96c26.5 0 48-21.5 48-48z' />
                                </svg>
                                <span>Pause</span>
                            </div>
                        </>
                    )}

                    {playStatus !== 'stopped' && (
                        <div className={[classes.Action, classes.SmallIconWrapper].join(' ')} onClick={stopAudio}>
                            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'>
                                <path d='M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48z' />
                            </svg>
                            <span>Stop</span>
                        </div>
                    )}

                    {/* <div className={classes.Action}>
                        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
                            <path d='M502.61 233.32L278.68 9.39c-12.52-12.52-32.83-12.52-45.36 0L9.39 233.32c-12.52 12.53-12.52 32.83 0 45.36l223.93 223.93c12.52 12.53 32.83 12.53 45.36 0l223.93-223.93c12.52-12.53 12.52-32.83 0-45.36zm-100.98 12.56l-84.21 77.73c-5.12 4.73-13.43 1.1-13.43-5.88V264h-96v64c0 4.42-3.58 8-8 8h-32c-4.42 0-8-3.58-8-8v-80c0-17.67 14.33-32 32-32h112v-53.73c0-6.97 8.3-10.61 13.43-5.88l84.21 77.73c3.43 3.17 3.43 8.59 0 11.76z' />
                        </svg>
                        <span>Directions</span>
                    </div> */}
                </div>

                <div className={classes.Title}>
                    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 384 512'>
                        <path d='M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z' />
                    </svg>

                    <h3>{selectedLocation['name_' + lang]}</h3>
                </div>

                <div className={classes.Text} dangerouslySetInnerHTML={{ __html: selectedLocation['description_' + lang] }} />
            </div>
        )
    );
};

export default LocationDetails;
