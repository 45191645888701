import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import Home from './pages/Home/Home';
import Map from './pages/Map/Map';
import Locations from './pages/Locations/Locations';
import Settings from './pages/Settings/Settings';
import ErrorPage from './pages/ErrorPage/ErrorPage';
import InitApp from './features/InitApp/InitApp';
import Layout from './features/Layout/Layout';
import Page404 from './pages/ErrorPage/features/Page404';

function App() {
    const router = createBrowserRouter([
        {
            element: <InitApp />,
            errorElement: <ErrorPage />,
            children: [
                {
                    path: '/',
                    element: <Layout />,
                    children: [
                        { index: true, element: <Home /> },
                        { path: '/map', element: <Map /> },
                        { path: '/locations', element: <Locations /> },
                        { path: '/settings', element: <Settings /> },
                        { path: '*', element: <Page404 /> },
                    ],
                },
            ],
        },
    ]);

    return <RouterProvider router={router} />;
}

export default App;
