import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isMobileWidth: 1000,
};

export const layoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {
        // setPageTitle: (state, action) => {
        //     state.pageTitle = action.payload;
        // },
    },
});

export const layoutActions = layoutSlice.actions;

export default layoutSlice;
