import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    selectedLocation: null,
    selectedLocationImages: [],
};

export const locationsSlice = createSlice({
    name: 'locations',
    initialState,
    reducers: {
        reset: (state) => {
            state.selectedLocation = null;
        },
        setSelectedLocation: (state, action) => {
            let imagesArr = [];

            if (action.payload.images.length) {
                const foundImages = action.payload.images.filter((i) => i.location_id === action.payload.location.id);

                for (let i = 0; i < foundImages.length; i++) {
                    imagesArr.push(foundImages[i].name);
                }
            }

            state.selectedLocation = action.payload.location;
            state.selectedLocationImages = imagesArr;
        },
    },
});

export const locationsActions = locationsSlice.actions;

export default locationsSlice;
