import { useSelector, useDispatch } from 'react-redux';

import Location from './features/Location';
import classes from './Locations.module.css';
import useTranslation from '../../hooks/useTranslation';
import Modal from '../../features/UI/Modal/Modal';
import LocationDetails from '../../features/LocationDetails/LocationDetails';
import { locationsActions } from './locationsSlice';
import Header from '../../features/Header/Header';
import Footer from '../../features/Footer/Footer';

const Locations = () => {
    const dispatch = useDispatch();

    const locations = useSelector((state) => state.app.locations);
    const locationsImages = useSelector((state) => state.app.locationsImages);
    const selectedLocation = useSelector((state) => state.locations.selectedLocation);

    return (
        <div className={classes.Page}>
            <Header />

            <div className={classes.PageContent}>
                <div className={classes.TitleRow}>
                    <span>{useTranslation('Name')}</span>
                    <span>{useTranslation('View')}</span>
                    <span>{useTranslation('Map')}</span>
                </div>

                {locations.map((location, index) => {
                    return (
                        <Location
                            key={index}
                            location={location}
                            index={index + 1}
                            onShowDetails={() => dispatch(locationsActions.setSelectedLocation({ location: location, images: locationsImages }))}
                        />
                    );
                })}
            </div>

            <Footer />

            <Modal show={selectedLocation} onClose={() => dispatch(locationsActions.setSelectedLocation({ location: null, images: [] }))}>
                {selectedLocation ? <LocationDetails /> : null}
            </Modal>
        </div>
    );
};

export default Locations;
