import { useDispatch, useSelector } from 'react-redux';

import MainButton from '../../../features/UI/MainButton/MainButton';
import useTranslation from '../../../hooks/useTranslation';
import { locationsActions } from '../../Locations/locationsSlice';

const LocationPopup = (props) => {
    const dispatch = useDispatch();

    const lang = useSelector((state) => state.app.lang);
    const locationsImages = useSelector((state) => state.app.locationsImages);

    const gotoLocationDetails = () => {
        dispatch(locationsActions.setSelectedLocation({ location: props.location, images: locationsImages }));
    };

    return (
        <div>
            <h4>{props.location['name_' + lang]}</h4>
            <MainButton fullWidth label={useTranslation('View location')} color='action' onClick={() => gotoLocationDetails()} />
        </div>
    );
};

export default LocationPopup;
