import { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { SnackbarProvider, closeSnackbar } from 'notistack';

import { showError } from '../../utils/messageHandler';
import axiosApi from '../../axios-api';
import Loader from '../Loader/Loader';

import { appActions } from '../../app/appSlice';
import { getFontSize, getLang, getLineHeight, getSaturation, getTextSpacing } from '../../utils/storage';

const InitApp = () => {
    const dispatch = useDispatch();
    const [dataLoaded, setDataLoaded] = useState(false);
    const snackbarRef = useRef();

    // Loads once on start
    useEffect(() => {
        const lang = getLang();
        dispatch(appActions.setPreferredLanguage(lang));

        const fontSize = getFontSize();
        dispatch(appActions.setFontSize(fontSize));

        const lineHeight = getLineHeight();
        dispatch(appActions.setLineHeight(lineHeight));

        const textSpacing = getTextSpacing();
        dispatch(appActions.setTextSpacing(textSpacing));

        const saturation = getSaturation();
        dispatch(appActions.setSaturation(saturation));

        axiosApi
            .get('api/getInitialData')
            .then((res) => {
                if (res.data.isValid === false) {
                    throw Error('Forbidden');
                } else {
                    dispatch(appActions.setInitialData(res.data));
                    setDataLoaded(true);
                }
            })
            .catch((error) => {
                showError(error, true);
            });
    }, [dispatch]);

    return (
        <SnackbarProvider
            ref={snackbarRef}
            maxSnack={3}
            autoHideDuration={3000}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            action={(snackbarId) => (
                <>
                    <svg
                        onClick={() => closeSnackbar(snackbarId)}
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        className='dismissNotistack'
                    >
                        <line x1='18' y1='6' x2='6' y2='18'></line>
                        <line x1='6' y1='6' x2='18' y2='18'></line>
                    </svg>
                </>
            )}
        >
            {dataLoaded ? <Outlet /> : <Loader show={true} />}
        </SnackbarProvider>
    );
};

export default InitApp;
