import { createSlice, current } from '@reduxjs/toolkit';

const initialState = {
    initialLoading: false,
    locations: null,
    thessaloniki: null,
    translations: null,
    locationsImages: null,
    lang: 'en',
    zoomToLocation: null,
    directions: null,
    directionsType: 'driving-car',
    geolocation: null,
    checkedGeolocation: false,
    fontSize: 'normal',
    lineHeight: 'normal',
    textSpacing: 'normal',
    saturation: 'normal',
    // tileLayer: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
    // tileLayerAttribution: '',
};

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setInitialLoading: (state, action) => {
            state.initialLoading = action.payload;
        },
        setInitialData: (state, action) => {
            state.locations = action.payload.locations;
            state.thessaloniki = action.payload.thessaloniki;
            state.translations = action.payload.translations;
            state.locationsImages = action.payload.locationsImages;
        },
        setPreferredLanguage: (state, action) => {
            state.lang = action.payload;
        },
        toggleLocationStatus: (state, action) => {
            const locationToUpdate = action.payload;

            const currentLocations = current(state).locations.map((location) =>
                location.id === locationToUpdate.id ? { ...location, remove: !location.remove } : location
            );

            state.locations = currentLocations;
        },
        updateLocationIndex: (state, action) => {
            let currentLocations = [...current(state).locations];

            const locationToMove = action.payload.location;
            let moveAction = action.payload.action;

            const fromIndex = currentLocations.findIndex((l) => l.id === locationToMove.id);
            const toIndex = moveAction === 'minus' ? fromIndex - 1 : fromIndex + 1;

            currentLocations.splice(fromIndex, 1);
            currentLocations.splice(toIndex, 0, locationToMove);

            state.locations = currentLocations;
        },
        setZoomToLocation: (state, action) => {
            state.zoomToLocation = action.payload;
        },
        setDirections: (state, action) => {
            if (action.payload && action.payload.features && action.payload.features.length) {
                state.directions = action.payload;
            } else {
                state.directions = null;
            }
        },
        setDirectionsType: (state, action) => {
            state.directionsType = action.payload;
        },
        setGeoLocation: (state, action) => {
            state.geolocation = action.payload;
        },
        setCheckedGeoLocation: (state, action) => {
            state.checkedGeolocation = action.payload;
        },
        setFontSize: (state, action) => {
            state.fontSize = action.payload;
            document.body.setAttribute('font-size', action.payload);
            localStorage.setItem('fontsize', action.payload);
        },
        setLineHeight: (state, action) => {
            state.lineHeight = action.payload;
            document.body.setAttribute('line-height', action.payload);
            localStorage.setItem('lineheight', action.payload);
        },
        setTextSpacing: (state, action) => {
            state.textSpacing = action.payload;
            document.body.setAttribute('text-spacing', action.payload);
            localStorage.setItem('textspacing', action.payload);
        },
        setSaturation: (state, action) => {
            state.saturation = action.payload;
            document.body.setAttribute('saturation', action.payload);
            localStorage.setItem('saturation', action.payload);
        },
    },
});

export const appActions = appSlice.actions;

export default appSlice;
