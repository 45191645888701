import { enqueueSnackbar } from 'notistack';

export function showMessage(message, type, persist = false) {
    enqueueSnackbar(message, { variant: type, persist: persist });
}

export function showError(error, persist) {
    if (error.code === 'ERR_CANCELED') return;

    let message = 'Something went wrong. Please try again.';
    if (error?.response?.data?.message) message = error.response.data.message;
    else if (error?.message) message = error.message;

    enqueueSnackbar(message, { variant: 'error', persist: persist });
}
