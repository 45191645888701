import { useNavigate } from 'react-router-dom';

import error_icon from '../../../assets/images/error.svg';
import MainButton from '../../../features/UI/MainButton/MainButton';
import classes from './Page500.module.css';
import useTranslation from '../../../hooks/useTranslation';

const Page500 = (props) => {
    const navigate = useNavigate();

    return (
        <div className={classes.Page500}>
            <div className={classes.Page500Inner}>
                <p className={classes.Title}>{useTranslation('Error!')}</p>
                <p className={classes.Subtitle}>{useTranslation('Something went wrong!')}</p>
                {/* <p>Error message: {props.error.message}</p> */}
                <img src={error_icon} alt='error icon' />
                <div className={classes.ButtonWrapper}>
                    <MainButton label={useTranslation('Go back')} color='action' onClick={() => navigate('/')} />
                </div>
            </div>
        </div>
    );
};

export default Page500;
