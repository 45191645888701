import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Header from '../../features/Header/Header';
import thessaloniki_img from '../../assets/images/thessaloniki.jpg';
import classes from './Home.module.css';
import Footer from '../../features/Footer/Footer';

const Home = () => {
    const navigate = useNavigate();
    const [playStatus, setPlayStatus] = useState('stopped');

    const thessaloniki = useSelector((state) => state.app.thessaloniki);
    const lang = useSelector((state) => state.app.lang);

    const audioFile = useMemo(() => {
        if (!thessaloniki) return null;
        const audioFilePath = 'thessaloniki_' + lang + '.mp3';
        if (!audioFilePath) return null;

        return new Audio(`${process.env.PUBLIC_URL}/mp3/${audioFilePath}`);
    }, [thessaloniki]);

    useEffect(() => {
        return () => {
            stopAudio();
        };
    }, []);

    const playAudio = () => {
        if (!audioFile) return;
        audioFile.play();
        setPlayStatus('playing');
    };

    const pauseAudio = () => {
        if (!audioFile) return;
        audioFile.pause();
        setPlayStatus('paused');
    };

    const stopAudio = () => {
        if (!audioFile) return;
        audioFile.pause();
        audioFile.currentTime = 0;
        setPlayStatus('stopped');
    };

    return (
        <div className={classes.Page}>
            <Header />

            <div className={classes.PageContent}>
                <h1>ALTERTRIP: ALTERNATIVE TOURISTIC EXPERIENCE</h1>

                <div className={classes.HomeImage}>
                    <img src={thessaloniki_img} alt='Thessaloniki' />

                    <div className={classes.Actions}>
                        {playStatus !== 'playing' && (
                            <div className={classes.Action} onClick={playAudio}>
                                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
                                    <path d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm115.7 272l-176 101c-15.8 8.8-35.7-2.5-35.7-21V152c0-18.4 19.8-29.8 35.7-21l176 107c16.4 9.2 16.4 32.9 0 42z' />
                                </svg>
                                <span>{playStatus === 'stopped' ? 'Listen' : 'Continue'}</span>
                            </div>
                        )}

                        {playStatus === 'playing' && (
                            <>
                                <div className={[classes.Action, classes.SmallIconWrapper].join(' ')} onClick={pauseAudio}>
                                    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'>
                                        <path d='M144 479H48c-26.5 0-48-21.5-48-48V79c0-26.5 21.5-48 48-48h96c26.5 0 48 21.5 48 48v352c0 26.5-21.5 48-48 48zm304-48V79c0-26.5-21.5-48-48-48h-96c-26.5 0-48 21.5-48 48v352c0 26.5 21.5 48 48 48h96c26.5 0 48-21.5 48-48z' />
                                    </svg>
                                    <span>Pause</span>
                                </div>
                            </>
                        )}

                        {playStatus !== 'stopped' && (
                            <div className={[classes.Action, classes.SmallIconWrapper].join(' ')} onClick={stopAudio}>
                                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'>
                                    <path d='M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48z' />
                                </svg>
                                <span>Stop</span>
                            </div>
                        )}
                    </div>
                </div>

                <h3>{thessaloniki['name_' + lang]}</h3>
                <div className={classes.Description} dangerouslySetInnerHTML={{ __html: thessaloniki['description_' + lang] }} />
            </div>

            <Footer />
        </div>
    );
};

export default Home;
