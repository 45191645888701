// import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useTranslation from '../../../hooks/useTranslation';
import classes from './MapRoutes.module.css';
import { useState } from 'react';
import { appActions } from '../../../app/appSlice';

const MapRoutes = (props) => {
    const dispatch = useDispatch();

    const locations = useSelector((state) => state.app.locations);
    const directions = useSelector((state) => state.app.directions);
    const directionsType = useSelector((state) => state.app.directionsType);

    const [showEdit, setShowEdit] = useState(false);

    const lang = useSelector((state) => state.app.lang);

    return (
        <div className={showEdit ? [classes.MapRoutes, classes.ShowEdit].join(' ') : classes.MapRoutes}>
            <div className={classes.InfoList}>
                <div className={classes.Info}>
                    <span>{props.routeStops}</span>
                    <span>{useTranslation('Locations')}</span>
                </div>

                <div className={classes.Info}>
                    <span>{directions ? Math.round(directions.features[0].properties.summary.distance) : 0}</span>
                    <span>{useTranslation('Meters')}</span>
                </div>

                <div className={classes.ButtonsWrapper}>
                    <div
                        className={directionsType === 'driving-car' ? [classes.DrivingButton, classes.Active].join(' ') : classes.DrivingButton}
                        onClick={() => dispatch(appActions.setDirectionsType('driving-car'))}
                    >
                        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
                            <path d='M499.99 176h-59.87l-16.64-41.6C406.38 91.63 365.57 64 319.5 64h-127c-46.06 0-86.88 27.63-103.99 70.4L71.87 176H12.01C4.2 176-1.53 183.34.37 190.91l6 24C7.7 220.25 12.5 224 18.01 224h20.07C24.65 235.73 16 252.78 16 272v48c0 16.12 6.16 30.67 16 41.93V416c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32v-32h256v32c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32v-54.07c9.84-11.25 16-25.8 16-41.93v-48c0-19.22-8.65-36.27-22.07-48H494c5.51 0 10.31-3.75 11.64-9.09l6-24c1.89-7.57-3.84-14.91-11.65-14.91zm-352.06-17.83c7.29-18.22 24.94-30.17 44.57-30.17h127c19.63 0 37.28 11.95 44.57 30.17L384 208H128l19.93-49.83zM96 319.8c-19.2 0-32-12.76-32-31.9S76.8 256 96 256s48 28.71 48 47.85-28.8 15.95-48 15.95zm320 0c-19.2 0-48 3.19-48-15.95S396.8 256 416 256s32 12.76 32 31.9-12.8 31.9-32 31.9z' />
                        </svg>
                    </div>
                    <div
                        className={directionsType === 'foot-walking' ? [classes.WalkingButton, classes.Active].join(' ') : classes.WalkingButton}
                        onClick={() => dispatch(appActions.setDirectionsType('foot-walking'))}
                    >
                        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'>
                            <path d='M208 96c26.5 0 48-21.5 48-48S234.5 0 208 0s-48 21.5-48 48 21.5 48 48 48zm94.5 149.1l-23.3-11.8-9.7-29.4c-14.7-44.6-55.7-75.8-102.2-75.9-36-.1-55.9 10.1-93.3 25.2-21.6 8.7-39.3 25.2-49.7 46.2L17.6 213c-7.8 15.8-1.5 35 14.2 42.9 15.6 7.9 34.6 1.5 42.5-14.3L81 228c3.5-7 9.3-12.5 16.5-15.4l26.8-10.8-15.2 60.7c-5.2 20.8.4 42.9 14.9 58.8l59.9 65.4c7.2 7.9 12.3 17.4 14.9 27.7l18.3 73.3c4.3 17.1 21.7 27.6 38.8 23.3 17.1-4.3 27.6-21.7 23.3-38.8l-22.2-89c-2.6-10.3-7.7-19.9-14.9-27.7l-45.5-49.7 17.2-68.7 5.5 16.5c5.3 16.1 16.7 29.4 31.7 37l23.3 11.8c15.6 7.9 34.6 1.5 42.5-14.3 7.7-15.7 1.4-35.1-14.3-43zM73.6 385.8c-3.2 8.1-8 15.4-14.2 21.5l-50 50.1c-12.5 12.5-12.5 32.8 0 45.3s32.7 12.5 45.2 0l59.4-59.4c6.1-6.1 10.9-13.4 14.2-21.5l13.5-33.8c-55.3-60.3-38.7-41.8-47.4-53.7l-20.7 51.5z' />
                        </svg>
                    </div>

                    <div className={classes.EditButton} onClick={() => setShowEdit(!showEdit)}>
                        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
                            <path d='M497.9 142.1l-46.1 46.1c-4.7 4.7-12.3 4.7-17 0l-111-111c-4.7-4.7-4.7-12.3 0-17l46.1-46.1c18.7-18.7 49.1-18.7 67.9 0l60.1 60.1c18.8 18.7 18.8 49.1 0 67.9zM284.2 99.8L21.6 362.4.4 483.9c-2.9 16.4 11.4 30.6 27.8 27.8l121.5-21.3 262.6-262.6c4.7-4.7 4.7-12.3 0-17l-111-111c-4.8-4.7-12.4-4.7-17.1 0zM124.1 339.9c-5.5-5.5-5.5-14.3 0-19.8l154-154c5.5-5.5 14.3-5.5 19.8 0s5.5 14.3 0 19.8l-154 154c-5.5 5.5-14.3 5.5-19.8 0zM88 424h48v36.3l-64.5 11.3-31.1-31.1L51.7 376H88v48z' />
                        </svg>
                    </div>
                </div>
            </div>

            {showEdit && (
                <div className={classes.Locations}>
                    {locations.map((location, index) => {
                        return (
                            <div key={index} className={location.remove ? [classes.MapRoute, classes.Removed].join(' ') : classes.MapRoute}>
                                <span>
                                    {index + 1}. {location['name_' + lang]}
                                </span>

                                <div className={classes.Arrows}>
                                    {index > 0 && (
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            viewBox='0 0 448 512'
                                            onClick={() => dispatch(appActions.updateLocationIndex({ location: location, action: 'minus' }))}
                                        >
                                            <path d='M0 432V80c0-26.51 21.49-48 48-48h352c26.51 0 48 21.49 48 48v352c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48zm355.515-140.485l-123.03-123.03c-4.686-4.686-12.284-4.686-16.971 0L92.485 291.515c-7.56 7.56-2.206 20.485 8.485 20.485h246.059c10.691 0 16.045-12.926 8.486-20.485z' />
                                        </svg>
                                    )}
                                    {index < locations.length - 1 && (
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            viewBox='0 0 448 512'
                                            onClick={() => dispatch(appActions.updateLocationIndex({ location: location, action: 'plus' }))}
                                        >
                                            <path d='M448 80v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48h352c26.5 0 48 21.5 48 48zM92.5 220.5l123 123c4.7 4.7 12.3 4.7 17 0l123-123c7.6-7.6 2.2-20.5-8.5-20.5H101c-10.7 0-16.1 12.9-8.5 20.5z' />
                                        </svg>
                                    )}
                                </div>

                                <svg
                                    className={classes.RemoveFromRoute}
                                    xmlns='http://www.w3.org/2000/svg'
                                    viewBox='0 0 448 512'
                                    onClick={() => dispatch(appActions.toggleLocationStatus(location))}
                                >
                                    <path d='M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z' />
                                </svg>

                                <svg
                                    className={classes.AddToRoute}
                                    xmlns='http://www.w3.org/2000/svg'
                                    viewBox='0 0 448 512'
                                    onClick={() => dispatch(appActions.toggleLocationStatus(location))}
                                >
                                    <path d='M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z' />
                                </svg>
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default MapRoutes;
