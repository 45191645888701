import classes from './Footer.module.css';

const Footer = () => {
    return (
        <div className={classes.Footer}>
            <p>
                The project is co-funded by the European Union and by National resources of the participating countries within the Interreg IPA CBC Programme
                "Greece-Republic of North Macedonia" 2014-2020.
            </p>
            <p>
                This application has been produced with the financial assistance of the European Union. The contents of the application are sole responsibility
                of Altertrip and can in no way be taken to reflect the views of the European Union, the participating countries, the Managing Authority and the
                Joint Secretariat.
            </p>
        </div>
    );
};

export default Footer;
