import { configureStore } from '@reduxjs/toolkit';

import layoutSlice from '../features/Layout/layoutSlice';
import appSlice from './appSlice';
import locationsSlice from '../pages/Locations/locationsSlice';

export const store = configureStore({
    reducer: {
        app: appSlice.reducer,
        layout: layoutSlice.reducer,
        locations: locationsSlice.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});
