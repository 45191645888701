export function getLang() {
    let lang = localStorage.getItem('lang');

    if (!lang) lang = 'en';

    return lang;
}

export function setLang(lang) {
    localStorage.setItem('lang', lang);
}

export function getFontSize() {
    let fontSize = localStorage.getItem('fontsize');

    if (!fontSize) fontSize = 'normal';

    return fontSize;
}

export function getLineHeight() {
    let lineHeight = localStorage.getItem('lineheight');

    if (!lineHeight) lineHeight = 'normal';

    return lineHeight;
}

export function getTextSpacing() {
    let textSpacing = localStorage.getItem('textspacing');

    if (!textSpacing) textSpacing = 'normal';

    return textSpacing;
}

export function getSaturation() {
    let saturation = localStorage.getItem('saturation');

    if (!saturation) saturation = 'normal';

    return saturation;
}
