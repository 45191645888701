import { Outlet, useNavigation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import L from 'leaflet';

import Menu from '../Menu/Menu';
import Loader from '../Loader/Loader';
import classes from './Layout.module.css';

// Fix marker not showing
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const Layout = () => {
    const loading = useSelector((state) => state.app.loading);
    const isMobileWidth = useSelector((state) => state.layout.isMobileWidth);
    const isMobile = useMediaQuery({ query: '(max-width: ' + isMobileWidth + 'px)' });

    const navigation = useNavigation();

    let layoutClasses = [classes.Layout];
    if (isMobile) layoutClasses.push('IsMobile');
    if (loading) layoutClasses.push(classes.NoOverflow);

    return (
        <div className={layoutClasses.join(' ')}>
            <Loader show={loading || navigation.state === 'loading'} />

            <div className={classes.ContentWrapper}>
                <div className={classes.Content}>
                    <Outlet />
                </div>

                <div className={classes.Menu}>
                    <Menu />
                </div>
            </div>
        </div>
    );
};

export default Layout;
