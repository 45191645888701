import React from 'react';

import classes from './Modal.module.css';

const Modal = (props) => {
    let elClasses = [classes.ModalWrapper];

    if (props.show) elClasses.push(classes.Show);
    if (props.size === 'small') elClasses.push(classes.Small);

    return (
        <div className={elClasses.join(' ')}>
            <div className={classes.Backdrop}></div>
            <div className={classes.Modal}>
                <div className={classes.ModalInner}>
                    <div className={classes.CloseButtonWrapper} onClick={props.onClose}>
                        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 352 512'>
                            <path d='M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z' />
                        </svg>
                    </div>

                    <div className={classes.ModalContent}>
                        {props.loading ? (
                            <div className={classes.Loading}>
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='24'
                                    height='24'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    stroke='currentColor'
                                    strokeWidth='2'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    className={props.loading ? [classes.Spinner, classes.Show].join(' ') : classes.Spinner}
                                >
                                    <line x1='12' y1='2' x2='12' y2='6'></line>
                                    <line x1='12' y1='18' x2='12' y2='22'></line>
                                    <line x1='4.93' y1='4.93' x2='7.76' y2='7.76'></line>
                                    <line x1='16.24' y1='16.24' x2='19.07' y2='19.07'></line>
                                    <line x1='2' y1='12' x2='6' y2='12'></line>
                                    <line x1='18' y1='12' x2='22' y2='12'></line>
                                    <line x1='4.93' y1='19.07' x2='7.76' y2='16.24'></line>
                                    <line x1='16.24' y1='7.76' x2='19.07' y2='4.93'></line>
                                </svg>
                            </div>
                        ) : (
                            props.children
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;
