import { useSelector } from 'react-redux';

import MainRadio from '../../features/UI/MainRadio/MainRadio';
import { setLang } from '../../utils/storage';
import classes from './Settings.module.css';
import { appActions } from '../../app/appSlice';
import { useDispatch } from 'react-redux';
import useTranslation from '../../hooks/useTranslation';
import MainButton from '../../features/UI/MainButton/MainButton';
import Header from '../../features/Header/Header';
import Footer from '../../features/Footer/Footer';

const Settings = () => {
    const dispatch = useDispatch();

    const lang = useSelector((state) => state.app.lang);
    const fontSize = useSelector((state) => state.app.fontSize);
    const lineHeight = useSelector((state) => state.app.lineHeight);
    const textSpacing = useSelector((state) => state.app.textSpacing);
    const saturation = useSelector((state) => state.app.saturation);

    const updateLang = (lang) => {
        setLang(lang);
        dispatch(appActions.setPreferredLanguage(lang));
    };

    const resetAccessibility = () => {
        dispatch(appActions.setFontSize('normal'));
        dispatch(appActions.setLineHeight('normal'));
        dispatch(appActions.setTextSpacing('normal'));
        dispatch(appActions.setSaturation('normal'));
    };

    return (
        <div className={classes.Page}>
            <Header />

            <div className={classes.PageContent}>
                <div className={classes.SettingsItem}>
                    <div className={classes.Title}>
                        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 640 512'>
                            <path d='M152.1 236.2c-3.5-12.1-7.8-33.2-7.8-33.2h-.5s-4.3 21.1-7.8 33.2l-11.1 37.5H163zM616 96H336v320h280c13.3 0 24-10.7 24-24V120c0-13.3-10.7-24-24-24zm-24 120c0 6.6-5.4 12-12 12h-11.4c-6.9 23.6-21.7 47.4-42.7 69.9 8.4 6.4 17.1 12.5 26.1 18 5.5 3.4 7.3 10.5 4.1 16.2l-7.9 13.9c-3.4 5.9-10.9 7.8-16.7 4.3-12.6-7.8-24.5-16.1-35.4-24.9-10.9 8.7-22.7 17.1-35.4 24.9-5.8 3.5-13.3 1.6-16.7-4.3l-7.9-13.9c-3.2-5.6-1.4-12.8 4.2-16.2 9.3-5.7 18-11.7 26.1-18-7.9-8.4-14.9-17-21-25.7-4-5.7-2.2-13.6 3.7-17.1l6.5-3.9 7.3-4.3c5.4-3.2 12.4-1.7 16 3.4 5 7 10.8 14 17.4 20.9 13.5-14.2 23.8-28.9 30-43.2H412c-6.6 0-12-5.4-12-12v-16c0-6.6 5.4-12 12-12h64v-16c0-6.6 5.4-12 12-12h16c6.6 0 12 5.4 12 12v16h64c6.6 0 12 5.4 12 12zM0 120v272c0 13.3 10.7 24 24 24h280V96H24c-13.3 0-24 10.7-24 24zm58.9 216.1L116.4 167c1.7-4.9 6.2-8.1 11.4-8.1h32.5c5.1 0 9.7 3.3 11.4 8.1l57.5 169.1c2.6 7.8-3.1 15.9-11.4 15.9h-22.9a12 12 0 0 1-11.5-8.6l-9.4-31.9h-60.2l-9.1 31.8c-1.5 5.1-6.2 8.7-11.5 8.7H70.3c-8.2 0-14-8.1-11.4-15.9z' />
                        </svg>
                        <span>{useTranslation('Language')}</span>
                    </div>

                    <div>
                        <MainRadio name='lang' text={useTranslation('English')} checked={lang === 'en' ? true : false} onChange={() => updateLang('en')} />
                        <MainRadio name='lang' text={useTranslation('Greek')} checked={lang === 'gr' ? true : false} onChange={() => updateLang('gr')} />
                    </div>
                </div>

                <div className={classes.SettingsItem}>
                    <div className={classes.Title}>
                        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
                            <path d='M256 48c114.953 0 208 93.029 208 208 0 114.953-93.029 208-208 208-114.953 0-208-93.029-208-208 0-114.953 93.029-208 208-208m0-40C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 56C149.961 64 64 149.961 64 256s85.961 192 192 192 192-85.961 192-192S362.039 64 256 64zm0 44c19.882 0 36 16.118 36 36s-16.118 36-36 36-36-16.118-36-36 16.118-36 36-36zm117.741 98.023c-28.712 6.779-55.511 12.748-82.14 15.807.851 101.023 12.306 123.052 25.037 155.621 3.617 9.26-.957 19.698-10.217 23.315-9.261 3.617-19.699-.957-23.316-10.217-8.705-22.308-17.086-40.636-22.261-78.549h-9.686c-5.167 37.851-13.534 56.208-22.262 78.549-3.615 9.255-14.05 13.836-23.315 10.217-9.26-3.617-13.834-14.056-10.217-23.315 12.713-32.541 24.185-54.541 25.037-155.621-26.629-3.058-53.428-9.027-82.141-15.807-8.6-2.031-13.926-10.648-11.895-19.249s10.647-13.926 19.249-11.895c96.686 22.829 124.283 22.783 220.775 0 8.599-2.03 17.218 3.294 19.249 11.895 2.029 8.601-3.297 17.219-11.897 19.249z' />
                        </svg>
                        <span>{useTranslation('Accessibility')}</span>
                    </div>

                    <div>
                        <div className={classes.Multiple}>
                            <span>{useTranslation('Font size')}:</span>
                            <div className={classes.Options}>
                                <span className={fontSize === 'normal' && classes.Active} onClick={() => dispatch(appActions.setFontSize('normal'))}>
                                    Aa
                                </span>
                                <span className={fontSize === 'large' && classes.Active} onClick={() => dispatch(appActions.setFontSize('large'))}>
                                    Aa
                                </span>
                                <span className={fontSize === 'very large' && classes.Active} onClick={() => dispatch(appActions.setFontSize('very large'))}>
                                    Aa
                                </span>
                            </div>
                        </div>

                        <div className={classes.Multiple}>
                            <span>{useTranslation('Line height')}:</span>
                            <div className={classes.LineOptions}>
                                <svg
                                    className={lineHeight === 'normal' && classes.Active}
                                    onClick={() => dispatch(appActions.setLineHeight('normal'))}
                                    xmlns='http://www.w3.org/2000/svg'
                                    viewBox='0 0 17.54 14.16'
                                >
                                    <rect width='17.54' height='4.7' />
                                    <rect y='9.47' width='17.54' height='4.7' />
                                </svg>
                                <svg
                                    className={lineHeight === 'large' && classes.Active}
                                    onClick={() => dispatch(appActions.setLineHeight('large'))}
                                    xmlns='http://www.w3.org/2000/svg'
                                    viewBox='0 0 17.54 21.63'
                                >
                                    <rect width='17.54' height='4.7' />
                                    <rect y='16.93' width='17.54' height='4.7' />
                                </svg>
                                <svg
                                    className={lineHeight === 'very large' && classes.Active}
                                    onClick={() => dispatch(appActions.setLineHeight('very large'))}
                                    xmlns='http://www.w3.org/2000/svg'
                                    viewBox='0 0 17.54 28.51'
                                >
                                    <rect width='17.54' height='4.7' />
                                    <rect y='23.82' width='17.54' height='4.7' />
                                </svg>
                            </div>
                        </div>

                        <div className={classes.Multiple}>
                            <span>{useTranslation('Text spacing')}:</span>
                            <div className={classes.TextOptions}>
                                <svg
                                    className={textSpacing === 'normal' && classes.Active}
                                    onClick={() => dispatch(appActions.setTextSpacing('normal'))}
                                    xmlns='http://www.w3.org/2000/svg'
                                    viewBox='0 0 14.16 17.54'
                                >
                                    <rect x='-6.42' y='6.42' width='17.54' height='4.7' transform='translate(-6.42 11.12) rotate(-90)' />
                                    <rect x='3.04' y='6.42' width='17.54' height='4.7' transform='translate(3.04 20.59) rotate(-90)' />
                                </svg>
                                <svg
                                    className={textSpacing === 'large' && classes.Active}
                                    onClick={() => dispatch(appActions.setTextSpacing('large'))}
                                    xmlns='http://www.w3.org/2000/svg'
                                    viewBox='0 0 21.63 17.54'
                                >
                                    <rect x='-6.42' y='6.42' width='17.54' height='4.7' transform='translate(-6.42 11.12) rotate(-90)' />
                                    <rect x='10.51' y='6.42' width='17.54' height='4.7' transform='translate(10.51 28.05) rotate(-90)' />
                                </svg>
                                <svg
                                    className={textSpacing === 'very large' && classes.Active}
                                    onClick={() => dispatch(appActions.setTextSpacing('very large'))}
                                    xmlns='http://www.w3.org/2000/svg'
                                    viewBox='0 0 28.51 17.54'
                                >
                                    <rect x='-6.42' y='6.42' width='17.54' height='4.7' transform='translate(-6.42 11.12) rotate(-90)' />
                                    <rect x='17.39' y='6.42' width='17.54' height='4.7' transform='translate(17.39 34.94) rotate(-90)' />
                                </svg>
                            </div>
                        </div>

                        <div className={classes.Multiple}>
                            <span>{useTranslation('Saturation')}:</span>
                            <div className={classes.SaturationOptions}>
                                <svg
                                    className={saturation === 'low' && classes.Active}
                                    onClick={() => dispatch(appActions.setSaturation('low'))}
                                    xmlns='http://www.w3.org/2000/svg'
                                    viewBox='0 0 512 512'
                                >
                                    <path d='M8 256c0 136.966 111.033 248 248 248s248-111.034 248-248S392.966 8 256 8 8 119.033 8 256zm248 184V72c101.705 0 184 82.311 184 184 0 101.705-82.311 184-184 184z' />
                                </svg>

                                <svg
                                    className={saturation === 'normal' && classes.Active}
                                    onClick={() => dispatch(appActions.setSaturation('normal'))}
                                    xmlns='http://www.w3.org/2000/svg'
                                    viewBox='0 0 512 512'
                                >
                                    <path d='M8 256c0 136.966 111.033 248 248 248s248-111.034 248-248S392.966 8 256 8 8 119.033 8 256zm248 184V72c101.705 0 184 82.311 184 184 0 101.705-82.311 184-184 184z' />
                                </svg>

                                <svg
                                    className={saturation === 'high' && classes.Active}
                                    onClick={() => dispatch(appActions.setSaturation('high'))}
                                    xmlns='http://www.w3.org/2000/svg'
                                    viewBox='0 0 512 512'
                                >
                                    <path d='M8 256c0 136.966 111.033 248 248 248s248-111.034 248-248S392.966 8 256 8 8 119.033 8 256zm248 184V72c101.705 0 184 82.311 184 184 0 101.705-82.311 184-184 184z' />
                                </svg>
                            </div>
                        </div>
                    </div>

                    <div className={classes.ButtonWrapper}>
                        <MainButton fullWidth label={useTranslation('Reset accessibility settings')} color='warning' onClick={resetAccessibility} />
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default Settings;
