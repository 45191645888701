import { useRouteError } from 'react-router-dom';

import Page404 from './features/Page404';
import Page500 from './features/Page500';

const ErrorPage = () => {
    const error = useRouteError();

    let page = <Page404 />;

    if (error.status !== 404) {
        page = <Page500 error={error} />;
    }

    return page;
};

export default ErrorPage;
