import React from 'react';
import { useNavigate } from 'react-router-dom';

import project_logo from '../../assets/images/project_logo.svg';
import classes from './Logo.module.css';

const Logo = () => {
    const navigate = useNavigate();

    return (
        <div className={classes.Logo}>
            <img src={project_logo} alt='Project logo' onClick={() => navigate('/')} />
        </div>
    );
};

export default Logo;
