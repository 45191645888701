import React from 'react';

import Logo from '../Logo/Logo';
import classes from './Loader.module.css';

const Loader = (props) => {
    let loaderClasses = [classes.LoaderWrapper];
    if (props.show) {
        loaderClasses.push(classes.Show);
    }

    return (
        <div className={loaderClasses.join(' ')} style={{ backgroundColor: props.background }}>
            <div className={classes.Loader}>
                <Logo />

                <div className={classes.Text}>
                    <h1>ALTER TRIP</h1>
                    <p>
                        Accessible and inclusive <br />
                        tourist destinations
                    </p>
                </div>
            </div>

            <div className={classes.LoadingWrapper}>
                <div className={classes.Loading}>Loading</div>
                <div className={classes.DotPulse}></div>
            </div>
        </div>
    );
};

export default Loader;
