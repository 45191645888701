import axiosApi from '../../axios-api';

import { showError } from '../../utils/messageHandler';
import { appActions } from '../../app/appSlice';

// Get data
export const getRoute = (waypoints, type) => {
    return (dispatch) => {
        const payload = {
            coordinates: waypoints,
            type: type,
        };

        axiosApi
            .post('api/getDirections', payload)
            .then((res) => {
                if (res.data.isValid === false) {
                    throw Error('Forbidden');
                } else {
                    dispatch(appActions.setDirections(res.data));
                }
            })
            .catch((error) => {
                showError(error, true);
            });
    };
};
