import { useDispatch, useSelector } from 'react-redux';

import useTranslation from '../../../hooks/useTranslation';
import { appActions } from '../../../app/appSlice';
import { showMessage } from '../../../utils/messageHandler';
import classes from './ZoomToGeolocation.module.css';

const ZoomToGeolocation = () => {
    const dispatch = useDispatch();
    const geolocation = useSelector((state) => state.app.geolocation);

    const geoNotAvailableTrans = useTranslation('Geolocation is not available');

    const zoom = () => {
        if (!geoNotAvailableTrans) return;
        if (!geolocation) {
            const message = (
                <div className='LearnMoreError'>
                    <span>{geoNotAvailableTrans}</span>
                    <span
                        className='LearnMoreButton'
                        onClick={() =>
                            window.open(
                                'https://support.google.com/maps/answer/2839911?hl=en&authuser=0&visit_id=638079224355221383-3252913396&co=GENIE.Platform%3DDesktop&oco=&p=browser_lp&rd=1#permission',
                                '_blank',
                                'noopener,noreferrer'
                            )
                        }
                    >
                        Learn more
                    </span>
                </div>
            );
            showMessage(message, 'error', true);
        } else {
            dispatch(appActions.setZoomToLocation({ lat: geolocation[0], lon: geolocation[1] }));
        }
    };

    return (
        <div className={classes.ZoomToGeolocation} onClick={zoom}>
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
                <path d='M500 224h-30.364C455.724 130.325 381.675 56.276 288 42.364V12c0-6.627-5.373-12-12-12h-40c-6.627 0-12 5.373-12 12v30.364C130.325 56.276 56.276 130.325 42.364 224H12c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h30.364C56.276 381.675 130.325 455.724 224 469.636V500c0 6.627 5.373 12 12 12h40c6.627 0 12-5.373 12-12v-30.364C381.675 455.724 455.724 381.675 469.636 288H500c6.627 0 12-5.373 12-12v-40c0-6.627-5.373-12-12-12zM288 404.634V364c0-6.627-5.373-12-12-12h-40c-6.627 0-12 5.373-12 12v40.634C165.826 392.232 119.783 346.243 107.366 288H148c6.627 0 12-5.373 12-12v-40c0-6.627-5.373-12-12-12h-40.634C119.768 165.826 165.757 119.783 224 107.366V148c0 6.627 5.373 12 12 12h40c6.627 0 12-5.373 12-12v-40.634C346.174 119.768 392.217 165.757 404.634 224H364c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h40.634C392.232 346.174 346.243 392.217 288 404.634zM288 256c0 17.673-14.327 32-32 32s-32-14.327-32-32c0-17.673 14.327-32 32-32s32 14.327 32 32z' />
            </svg>
        </div>
    );
};

export default ZoomToGeolocation;
