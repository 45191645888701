import { NavLink, useLocation } from 'react-router-dom';

import classes from './MenuItem.module.css';

const MenuItem = (props) => {
    const { pathname } = useLocation();
    const slashIndex = pathname.lastIndexOf('/');

    let pathnameNoParams = pathname;
    if (slashIndex > 0) pathnameNoParams = pathname.slice(0, slashIndex);

    return (
        <NavLink
            to={props.link}
            exact={props.exact}
            className={({ isActive }) =>
                isActive || props.location.includes(pathnameNoParams) ? [classes.NavLink, classes.Active].join(' ') : classes.NavLink
            }
        >
            {props.icon ? <div className={classes.ItemIconWrapper}>{props.icon}</div> : null}
            <span className={classes.ItemTitle}>{props.title}</span>
        </NavLink>
    );
};

export default MenuItem;
