import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import classes from './Location.module.css';
import { appActions } from '../../../app/appSlice';

const Location = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const lang = useSelector((state) => state.app.lang);

    const gotoMap = () => {
        dispatch(appActions.setZoomToLocation(props.location));
        navigate('/map');
    };

    return (
        <div className={classes.Row}>
            <span>{props.location['name_' + lang]}</span>

            <div className={classes.IconWrapper}>
                <div className={[classes.Icon, classes.IconView].join(' ')} onClick={props.onShowDetails}>
                    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
                        <path d='M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z' />
                    </svg>
                </div>
            </div>

            <div className={classes.IconWrapper}>
                <div className={[classes.Icon, classes.IconMap].join(' ')} onClick={gotoMap}>
                    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 384 512'>
                        <path d='M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z' />
                    </svg>
                </div>
            </div>
        </div>
    );
};

export default Location;
