import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const useTranslation = (name) => {
    const translations = useSelector((state) => state.app.translations);
    const lang = useSelector((state) => state.app.lang);

    const [data, setData] = useState(null);

    useEffect(() => {
        let label = name;
        let foundLabel = null;
        const found = translations.find((t) => t.name === name);

        if (found) foundLabel = found['label_' + lang];
        if (foundLabel) label = foundLabel;

        setData(label);
    }, [translations, lang, name]);

    return data;
};

export default useTranslation;
